import styled from "styled-components"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplyIcon from '@mui/icons-material/Reply';
import FaceIcon from '@mui/icons-material/Face';
import Comments from "../components/Comments";
import Card from "../components/Card"
import ReactTimeago from "react-timeago";
import { Link, useParams } from 'react-router-dom';
import { imagesData } from "../data/images";



const Container = styled.div`
    display: flex;
    justify-content: center;
    
`

const Content = styled.div`
    flex: 7;
    margin-right: 36px;
`

const Toolbox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
`

const ImageWrapper = styled.img`
    width: auto;
    max-height: calc(75vh - 15px);
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`
const TitleWithButtons = styled.div`
    display: flex;
    justify-content: space-between;
`

const Title = styled.h1`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    color: ${({theme}) => theme.text};
`

const Details = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Info = styled.span`
    display: flex;
    font-size: 12px;
    color: ${({theme}) => theme.textSoft};
    margin-top: 8px;
`

const Location = styled.span`
    display: flex;
    font-size: 8px;
    color: ${({theme}) => theme.textSoft};
    margin-top: 4px;
`

const Buttons = styled.div`
    display: flex;
    gap: 20px;
    color: ${({theme}) => theme.text}
    font-size: 10px;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 8px;
`

const Hr = styled.hr`
    margin: 15px 0px;
    border: 0.5px solid ${({theme}) => theme.soft};
`

const Recommendation = styled.div`
    flex: 1;
`

const Image = () => {

    const { id } = useParams();

    const image = imagesData.find((image) => image.id === parseInt(id));

    return (
        
           <Container>
                <Content>
                    <ImageWrapper src={image.imageUrl} />
                    <Toolbox>
                        <TitleWithButtons>
                        <Title>{image.name}</Title>
                        <Buttons>
                                <Button><ThumbUpIcon /> 4</Button>
                                <Button><ReplyIcon /> Share</Button>
                                <Link to={`/facematch/${image.id}`} style={{textDecoration: "None", color: "inherit"}} ><Button><FaceIcon /> Face Match</Button></Link>
                        </Buttons>
                        
                    </TitleWithButtons>
                    <Location>Taken near Cape Town</Location>
                    <Details>
                        <Info>{image.views} views &#8226; uploaded&nbsp;<ReactTimeago date={image.createDate} /></Info>                       
                    </Details>
                    </Toolbox>
                    <Hr />
                    <Comments />
                </Content>
                <Recommendation>
                    {imagesData.map((image) => <Card type={"sm"} image={image} key={image.id} /> )}
                </Recommendation>
           </Container>
           
    )
}

export default Image