import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Menu from './components/Menu';
import Home from './pages/Home';
import Image from './pages/Image';
import ImageSelector from './pages/ImageSelector';
import FaceMatch from './pages/FaceMatch';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './utils/theme';
import { useState } from 'react';
import SignIn from './pages/SignIn';

const Container = styled.div`
  display: flex;
`
const Main = styled.div`
  flex: 7;
  background-color: ${({theme}) => theme.bg};
`
const Wrapper = styled.div`
  padding: 22px 96px;
`


function App() {

  const [isDarkMode, toggleDarkMode] = useState(true);

  console.log("App js loading");

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Container>
        <Router>      
          <Menu isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
            <Main>
              <NavBar />
              <Wrapper>
                <Routes>
                  <Route exact path="/">
                    <Route index element={<Home />} />
                    <Route path="signin" element={<SignIn />} />
                    <Route path="image-selector" element={<ImageSelector />} />
                    <Route path="image">
                      <Route path=":id" element={<Image />} />
                    </Route>
                    <Route exact path="facematch" element={<FaceMatch />} />
                    <Route path="facematch/:id" element={<FaceMatch />} />
                  </Route>
                </Routes>
              </Wrapper>
            </Main>
          </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
