import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactTimeAgo from 'react-timeago';
import { ImageSizer } from '../utils/images';

const Container = styled.div`
    width: ${(props) =>  ImageSizer(props.type).maxWidth }px;
    margin-bottom: ${(props) => props.type === "sm" ? "16px" : "30px"};
    cursor: pointer;
    gap: 2px;
`

const Image = styled.img`
    max-width: ${(props) =>  ImageSizer(props.type).maxWidth }px;
    max-height: ${(props) =>  ImageSizer(props.type).maxWidth }px;
    background-color: #999;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    
`

const Details = styled.div`
    display: flex;
    margin-top: ${(props) => props.type !== "sm" && "2px"};
    flex: 1;
`

const Texts = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text};
`
const Info = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.textSoft};
    display: ${(props) => props.type === "sm" && "none"};
`

const Card = ({type, image}) => {

    return (
            <Link to={`/image/${image.id}`} style={{textDecoration: "none"}}>
            <Container type={type}>
                <Image type={type} src={image.thumbnailUrl} />
                <Details type={type}>
                    <Texts>
                        <Title>{image.name}</Title>
                        <Info>{image.views} Views - <ReactTimeAgo date={image.createDate} /></Info>
                    </Texts>
                </Details>    
            </Container>
            </Link>
           
    )
}

export default Card