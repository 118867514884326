import styled from 'styled-components';
import Comment from './Comment';


const Container = styled.div`
    
`

const NewComment = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

const Input = styled.input`
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.soft};
    background-color: transparent;
    outline: none;
    padding: 5px;
    width: 100%;
`

const Comments = () => {

    return (
        
            <Container>
                <NewComment>
                    <Avatar src="https://images.pexels.com/photos/1323550/pexels-photo-1323550.jpeg?auto=compress&cs=tinysrgb&w=250" />
                    <Input placeholder='Comment' />
                </NewComment>
                <Comment />
                <Comment />
                <Comment />
            </Container>
           
    )
}

export default Comments