// This function returns an object with the maxHeight and maxWidth values based on the provided size string.

import EXIF from "exif-js";

// If no valid size is provided, the function returns an object with default values.
export const ImageSizer = (size) => {

    let result = {};

    if(size === "sm") result = { maxHeight: 160, maxWidth: 160 }
    if(size === "md") result = { maxHeight: 180, maxWidth: 180 }
    if(size === "lg") result = { maxHeight: 600, maxWidth: 600 }

    if(!result) result =  { maxHeight: 80, maxWidth: 80 }

    return result;
}


// This function calculates the new dimensions (width and height) of an image based on the maximum dimensions (maxWidth and maxHeight) allowed while maintaining aspect ratio.
export const GetResizedDimensions = (maxHeight, maxWidth, currentHeight, currentWidth) => {
    let newWidth = currentWidth;
    let newHeight = currentHeight;
  
    // First, check if the current width exceeds the maximum width
    if (currentWidth > maxWidth) {
      // If it does, set the new width to the maximum width and calculate the new height to maintain aspect ratio
      newWidth = maxWidth;
      newHeight = (newWidth * currentHeight) / currentWidth;
    }
  
    // Then, check if the current height exceeds the maximum height
    if (newHeight > maxHeight) {
      // If it does, set the new height to the maximum height and calculate the new width to maintain aspect ratio
      newHeight = maxHeight;
      newWidth = (newHeight * currentWidth) / currentHeight;
    }

    const result = {
        width: newWidth,
        height: newHeight,
        scaleFactor: (newHeight / currentHeight)
    };

    console.log("Resized", result)

    return result;
}

export const ReadExifData = async (file) => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function () {
        const exifData = EXIF .readFromBinaryFile(this.result);
        resolve(exifData);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  
}

export const convertDMStoDD = (degrees, minutes, seconds, direction) => 
{
  var dd = degrees + minutes/60 + seconds/(60*60);

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  } // Don't do anything for N or E

  return dd;
}