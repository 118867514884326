export const imagesData = [
    {
        id: 1,
        name: "Girl with child",
        views: 20,
        createDate: '2023-04-06 16:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/4617294/pexels-photo-4617294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/4617294/pexels-photo-4617294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 19,
        name: "Another Family ",
        views: 1,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://images.pexels.com/photos/4262414/pexels-photo-4262414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/4262414/pexels-photo-4262414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 2,
        name: "Chair on Hill",
        views: 65,
        createDate: '2023-04-03 12:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/16053779/pexels-photo-16053779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/16053779/pexels-photo-16053779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 3,
        name: "Cat in a Basket",
        views: 200,
        createDate: '2023-02-03 12:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/15800210/pexels-photo-15800210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/15800210/pexels-photo-15800210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 4,
        name: "Jet on the runway",
        views: 130,
        createDate: '2023-04-09 15:45:30',
        thumbnailUrl: 'https://images.pexels.com/photos/1004106/pexels-photo-1004106.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        imageUrl: 'https://images.pexels.com/photos/1004106/pexels-photo-1004106.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1260'
    },
    {
        id: 5,
        name: "Green Forest",
        views: 320,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://images.pexels.com/photos/1477764/pexels-photo-1477764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/1477764/pexels-photo-1477764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 6,
        name: "Sunrise over the Mountains",
        views: 230,
        createDate: '2023-03-10 10:45:45',
        thumbnailUrl: 'https://images.pexels.com/photos/2113826/pexels-photo-2113826.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/2113826/pexels-photo-2113826.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 7,
        name: "Field of Flowers",
        views: 540,
        createDate: '2023-03-15 14:20:45',
        thumbnailUrl: 'https://images.pexels.com/photos/1666020/pexels-photo-1666020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/1666020/pexels-photo-1666020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 8,
        name: "City Lights at Night",
        views: 420,
        createDate: '2023-02-28 20:40:45',
        thumbnailUrl: 'https://images.pexels.com/photos/1666024/pexels-photo-1666024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/1666024/pexels-photo-1666024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 9,
        name: "Sunset over Mountains",
        views: 35,
        createDate: '2023-04-05 14:10:20',
        thumbnailUrl: 'https://images.pexels.com/photos/5474493/pexels-photo-5474493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/5474493/pexels-photo-5474493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      {
        id: 10,
        name: "Flower Field",
        views: 50,
        createDate: '2023-04-04 11:25:10',
        thumbnailUrl: 'https://images.pexels.com/photos/3824139/pexels-photo-3824139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/3824139/pexels-photo-3824139.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      {
        id: 11,
        name: "Bridge over River",
        views: 27,
        createDate: '2023-04-03 09:35:45',
        thumbnailUrl: 'https://images.pexels.com/photos/6591692/pexels-photo-6591692.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/6591692/pexels-photo-6591692.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      {
        id: 12,
        name: "Hiking Trail",
        views: 43,
        createDate: '2023-04-02 16:40:30',
        thumbnailUrl: 'https://images.pexels.com/photos/1743235/pexels-photo-1743235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/1743235/pexels-photo-1743235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      },
      {
        id: 13,
        name: "Girl with child",
        views: 20,
        createDate: '2023-04-06 16:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/4617294/pexels-photo-4617294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/4617294/pexels-photo-4617294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 14,
        name: "Chair on Hill",
        views: 65,
        createDate: '2023-04-03 12:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/16053779/pexels-photo-16053779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/16053779/pexels-photo-16053779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 15,
        name: "Cat in a Basket",
        views: 200,
        createDate: '2023-02-03 12:23:45',
        thumbnailUrl: 'https://images.pexels.com/photos/15800210/pexels-photo-15800210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/15800210/pexels-photo-15800210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 16,
        name: "Jet on the runway",
        views: 130,
        createDate: '2023-04-09 15:45:30',
        thumbnailUrl: 'https://images.pexels.com/photos/1004106/pexels-photo-1004106.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        imageUrl: 'https://images.pexels.com/photos/1004106/pexels-photo-1004106.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1260'
    },
    {
        id: 17,
        name: "Green Forest",
        views: 320,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://images.pexels.com/photos/1477764/pexels-photo-1477764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/1477764/pexels-photo-1477764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 18,
        name: "Family at Thanks Giving",
        views: 121,
        createDate: '2023-1-18 15:45:45',
        thumbnailUrl: 'https://images.pexels.com/photos/5638615/pexels-photo-5638615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/5638615/pexels-photo-5638615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },

    {
        id: 20,
        name: "Hard Faces",
        views: 77,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://images.pexels.com/photos/7713314/pexels-photo-7713314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        imageUrl: 'https://images.pexels.com/photos/7713314/pexels-photo-7713314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },

    {
        id: 30,
        name: "Jen Rugby",
        views: 77,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://i.ibb.co/3Ff68yR/IMG-20230324-213757.jpg',
        imageUrl: 'https://i.ibb.co/3Ff68yR/IMG-20230324-213757.jpg'
    },
    {
        id: 31,
        name: "Jen Rugby",
        views: 77,
        createDate: '2023-02-21 15:30:45',
        thumbnailUrl: 'https://i.ibb.co/f1t0nzH/IMG-1397.jpg',
        imageUrl: 'https://i.ibb.co/f1t0nzH/IMG-1397.jpg'
    },
    {
        id: 32,
        name: "Dad and the strange one",
        views: 77,
        createDate: '2022-12-21 15:30:45',
        thumbnailUrl: 'https://i.ibb.co/9w4YvtV/Screenshot-2023-04-13-184134.jpg',
        imageUrl: 'https://i.ibb.co/9w4YvtV/Screenshot-2023-04-13-184134.jpg'
    }
]