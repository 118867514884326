import styled from "styled-components"
import LoginIcon from '@mui/icons-material/Login';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Link } from "react-router-dom";

const Container = styled.div`
    position: sticky;
    top: 0;
    background-color: ${({theme}) => theme.bgLight}
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 5px 20px;
    position: relative;

`

const Search = styled.div`
    width: 40%;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;

`

const Input = styled.input`
    border: none;
    background-color: transparent;
`

const Button = styled.button`
    padding: 5px 15px;
    background-color: transparent;
    border: 1px solid #3ea6ff;
    color: #3ea6ff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    height: max-content;
`

const NavBar = () => {

    return (
        <Container>
            <Wrapper>
                <Search>
                    <Input placeholder="Search" />
                    <ImageSearchIcon />
                </Search>
                <Link to="/signin" style={{textDecoration:"none"}}>
                    <Button><LoginIcon /> Login</Button>
                </Link>
           </Wrapper>
        </Container>
    )
}

export default NavBar