import { useEffect } from 'react';
import { useState } from 'react';
import ImageUploadProgress from '../pages/ImageUploadProgress'
import styled from 'styled-components'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ReadExifData, convertDMStoDD} from '../utils/images';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px); 
    color: ${({theme}) => theme.text };
    gap: 40px;
`
const DropZone = styled.div`
    border: 2px dashed #ccc;
    padding: 50px;
    text-align: center;
`

const Preview = styled.div`
    width: 65vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
`

const PreviewImageWrapper = styled.div`
    position: relative;
    display: inline-block;
   
`

const Remove = styled.span`
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: red;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
`

const PreviewImage = styled.img`
    height: 200px;
    width: auto;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    &:hover{
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
    }
`

const ImageSelector = () => {
    console.log("Image Selector Loading");
    const [go, setGo] = useState(false);

    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const getImages = () => {
            let newImages = [];
            files.map((file) => {
                const img = new Image()
                img.src = file.previewUrl;
                let data = {};
                const getData = async () => {
                    
                    const data = await ReadExifData(file.file);
                    return data;
                }

                    getData().then((exif) => {

                    let gpsLatitude = 0;
                    let gpsLongitude = 0;

                    if(exif.GPSLatitude) {
                        gpsLatitude = convertDMStoDD(exif.GPSLatitude[0], exif.GPSLatitude[1], exif.GPSLatitude[2], exif.GPSLatitudeRef);
                        gpsLongitude = convertDMStoDD(exif.GPSLongitude[0], exif.GPSLongitude[1], exif.GPSLongitude[2], exif.GPSLongitudeRef);
                    }
                     
                    img.onload = () => {
                        const newImage = {
                            url: img.src,
                            width: img.width,
                            height: img.height,
                            size: file.file.size,
                            type: file.file.type,
                            gpsLongitude: gpsLongitude,
                            gpsLatitude: gpsLatitude,
                        }
                        newImages = [...newImages, newImage];
                        setImages(newImages);
                    }

                });
                
                
            })

        }
        files && getImages();
    }, [files])
    


    const handleFileInputChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
      
        setFiles(selectedFiles.map(file => ({
            file,
            previewUrl: URL.createObjectURL(file)
        })));
    }
      
    const handleRemoveButtonClick = (index) => {
          setFiles(files.filter((_, i) => i !== index));
    }
    

  return (
    <Container>
        {go ? <ImageUploadProgress images={images} /> : 
        <div>
            <DropZone>
                <input type="file" id="file-input" multiple onChange={handleFileInputChange} accept="image/jpeg, image/png"/>
                <label htmlFor="file-input">Drag and drop files here or click to select files</label>
            </DropZone>
            <Preview>
                {files.map((file, index) => (
                    <PreviewImageWrapper key={index}>
                        <PreviewImage src={file.previewUrl} alt={file.file.name} />
                        <Remove onClick={() => handleRemoveButtonClick(index)}><DeleteForeverIcon /></Remove>
                    </PreviewImageWrapper>
                ))}
            </Preview>
            
            {files.length > 0 && 
                <button onClick={() => setGo(true)}>Upload</button>
            }
            </div>
        }
    </Container>
  ) 
}

export default ImageSelector