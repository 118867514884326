import styled from 'styled-components';
import LogoImage from '../images/logo.png'
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CollectionsIcon from '@mui/icons-material/Collections';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import PartyModeIcon from '@mui/icons-material/PartyMode';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import PeopleIcon from '@mui/icons-material/People';
import PlaceIcon from '@mui/icons-material/Place';
import { Link } from 'react-router-dom';


const Container = styled.div`
    flex:1;
    background-color: ${({theme}) => theme.bgLight};
    height: 100vh;
    color: ${({theme}) => theme.text};
    font-size: 14px;
    position: sticky;
    top: 0;
`

const Wrapper = styled.div`
    padding: 18px 26px;
`

const Logo = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    margin-bottom: 25px;
`

const Img = styled.img`
    height: 25px;
`

const Item = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 7.5px 0px;
    &:hover{
        background-color: ${({theme})=>theme.soft};
    }
`

const Hr = styled.hr`
    margin: 15px 0px;
    border: 0.5px solid ${({theme}) => theme.soft};
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #aaaaaa;
    margin-bottom: 20px;

`

const Menu = ({isDarkMode, toggleDarkMode}) => {

    return (
        <Container >
            <Wrapper>
                <Link to="/" style={{textDecoration: "None", color: "inherit"}}>
                <Logo>
                    <Img src={LogoImage} />
                    Photo Album
                </Logo>
                </Link>
                <Link to="/" style={{textDecoration: "None", color: "inherit"}}>
                <Item>
                    <HomeIcon />
                    Home
                </Item>
                </Link>
                <Item>
                    <PersonIcon />
                    Profile
                </Item>
                <Item>
                    <PartyModeIcon />
                    Latest Photos
                </Item>
                <Item>
                    <CollectionsIcon />
                    Albums
                </Item>
                <Item>
                    <PeopleIcon />
                    People
                </Item>
                <Item>
                    <PlaceIcon />
                    Places
                </Item>
                <Hr />
                
                <Title>Photo Management</Title>
                <Link to="/image-selector" style={{textDecoration: "none", color: "inherit"}}>
                <Item>
                    <CloudUploadIcon />
                    Upload
                </Item>
                </Link>
                <Link to="/facematch" id="19" style={{textDecoration: "none", color: "inherit"}}>
                <Item>
                    <FaceRetouchingNaturalIcon />
                    Face Matcher
                </Item>
                </Link>
                <Hr />

                <Item>
                    <SettingsSuggestIcon />
                    Settings
                </Item>
                <Item>
                    <MeetingRoomIcon />
                    Logout
                </Item>
                <Hr />
                <Item onClick={() => toggleDarkMode(!isDarkMode)}>
                    <SettingsBrightnessIcon />
                    {isDarkMode ? "Light" : "Dark"} Mode
                </Item>
            </Wrapper>
        </Container>
    )
}

export default Menu