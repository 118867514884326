import styled from 'styled-components';
import { imagesData } from '../data/images';
import { Link } from 'react-router-dom';
import ReactTimeago from 'react-timeago';


const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.7em;
`

const PhotoWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    height: 20vh;
    position: relative;
    &:hover .Overlay {
        opacity: 1;
      }
`

const Overlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #030303, transparent);
    
    color: white;
    padding: 0.5rem;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    z-index: 100;
    width: calc(100% - 24px);
`

const OverlayTitle = styled.div`
    font-size: 12px;
    font-weight: 500;

`
const OverlayDetails = styled.div`
    font-size: 10px;
    font-weight: 300;
`


const Photo = styled.img`
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
    max-width: 300px;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
`

const Home = () => {

       return (
        
            <Container>
                {imagesData.map((image) => 
                    <PhotoWrapper key={image.id}>
                        <Link to={`/image/${image.id}`} >
                            <Photo src={image.imageUrl} />
                            <Overlay className="Overlay">
                                <OverlayTitle>{image.name}</OverlayTitle>
                                <OverlayDetails>{image.views} views, uploaded <ReactTimeago date={image.createDate} /></OverlayDetails>
                            </Overlay>
                        </Link>
                    </PhotoWrapper> )}
            </Container>
           
    )
}

export default Home