import styled from "styled-components";
import { GetResizedDimensions } from "../utils/images";
import { useEffect, useState } from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Info = styled.div`
    display: flex;
    color: ${({theme}) => theme.textSoft};
    margin-top: 15px;
`

const ImageWrapper = styled.div`
    display: flex;
`

const Image = styled.img`
    max-height: 60vh;
    max-width: auto;
`

const ProgressBar = styled.progress`
    margin-top: 10px;
    width: 100%;
`

const ImageUploadProgress = ({ images }) => {

    const displayDimensions = GetResizedDimensions(600, 800, images[0].height, images[0].width);

    // calculate the size in byte, that we're about to upload.
    const totalSizeBytes = images.reduce((acc, cur) => acc + cur.size, 0);
    // and convert to megabytes
    const totalSizeMB = totalSizeBytes / 1000000;
    // calculate the number of images we're uploading.
    const imageCount = images.length
  
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Upload the images in the images array.
    const uploadFiles = async () => {

        // Itterate through all the images...
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
           // console.log("Processing", image)
            

            await new Promise(resolve => setTimeout(resolve, 3000));
            // try {
            //     // send request to upload image to API
            //     await fetch("YOUR_API_URL", {
            //         method: "POST",
            //         body: image.file,
            //     });
            // } catch (error) {
            //     console.error(error);
            // }
            setCurrentImageIndex(i + 1);
            const progress = ((i + 1) / images.length) * 100;
            setUploadProgress(progress);
        }
    }


    useEffect(() => {
        uploadFiles();
    }, [])


    return (
        <Container>
            <Info>
                Upload Progress {imageCount} images will be uploaded. A total of {totalSizeMB.toFixed(1)} bytes
            </Info>

            {currentImageIndex < imageCount ?

                <div>
                    <Info>Uploading image {currentImageIndex + 1} or {imageCount}</Info>
                    <ImageWrapper>
                        <Image src={images[currentImageIndex].url} /> 
                    </ImageWrapper>
                    <ProgressBar value={uploadProgress} max="100" />
                </div>
            :
            <div>

            </div>
}
        </Container>
    )
}

export default ImageUploadProgress


