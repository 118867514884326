import { useEffect, useState, useRef } from "react";
import * as faceapi from "face-api.js";
import styled, { keyframes } from "styled-components";
import { GetResizedDimensions } from "../utils/images";
import { imagesData } from "../data/images";
import { useParams, Navigate } from "react-router-dom";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: left;
    
`

const InfoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    font-size: 14px;
    font-weight: 400;
    gap: 10px;
    color: ${({theme}) => theme.textSoft};
`
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loading = styled.div`
    border: 4px solid transparent; 
    border-top: 4px solid ${({theme}) => theme.text};;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: ${spin} 1s linear infinite;
`

const ImageWrapper = styled.div`
    position: relative;
`

const ImageDiv = styled.img`
    width: auto;
    max-height: calc(75vh - 15px);
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`

const FaceMatch = () => {

    let { id } = useParams();
    if(id === undefined)
        id = 31;

    const [appStatus, setAppStatus] = useState();
    const [mainImage, setMainImage] = useState();
    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [isBusy, setIsBusy] = useState(true);
    const [displayDimensions, setDisplayDimensions] = useState();
    const [detections, setDetections] = useState([]);
    const [selectedFace, setSelectedFace] = useState(null);
    const [name, setName] = useState("");
    const [labels, setLabels] = useState({});
    const imgRef                = useRef();
    const canvasRef             = useRef();

    useEffect(() => {
        const loadModels = async () => {
            console.log("Loading models...")
            await Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
                faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
                faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
                faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
            ])
            .then(() => {
                    console.log("Models Loaded"); 
                    setAppStatus("Detecting Faces...")
                    detectFacesInImage();  
                });
        }

        imgRef.current && loadModels()
            .catch((x) => console.log("Error", x));;
    }, [imageIsLoaded])


    // We need this stuff to load first, so we're using useEffect.
    useEffect(() => {

        const imageLoaded = () => {
            console.log("Image loaded.. setting state")
            setMainImage(img);
            console.log("Image stored in State", img)
            console.log(`Image actual size is ${img.width}*${img.height} - will check if we need to resize`)
            // Work out the display dimentions and store them.
            const displayDimensions = GetResizedDimensions(600, 800, img.height, img.width);
            setDisplayDimensions(displayDimensions);
            setImageIsLoaded(true);
            console.log("Image loaded successfully")
        }
        setAppStatus("Loading Models...")
        console.log("Face recognision started, Startup. Will load image...")
        // Get the image meta data.
        const imageMeta = imagesData.find((image) => image.id === parseInt(id));
        // Check image was found.
        if(!imageMeta) {
            console.log(`No image with the id ${id} was found. Booting the user back to home!`)
            return <Navigate to="/" />
        }
        // Load the image into an image object.
        const img = new Image()
        img.onload = imageLoaded;
        img.src = imageMeta.imageUrl;
        console.log("Startup method complete")
    }, [])

    const detectFacesInImage = async () => {
        console.log("Detecting faces in Image...");
        const detections = await faceapi
            .detectAllFaces(imgRef.current)
            .withFaceLandmarks()
            .withFaceDescriptors();

        console.log(`Detections found: ${detections.length}`)
        const resizedDetections = faceapi
            .resizeResults(detections, { width: displayDimensions.width, height: displayDimensions.height });

        setDetections(resizedDetections);    
        faceapi.matchDimensions(canvasRef.current, { width: displayDimensions.width, height: displayDimensions.height });
        setAppStatus(`${detections.length} Faces Detected.`)
        setIsBusy(false);
    };

    const handleFaceClick = (event, detection) => {
        console.log("Face clicked", detection)
        event.preventDefault();
        setSelectedFace(detection);
    };

    const handleNameChange = (event) => {
        console.log("Changing name")
        setName(event.target.value);
    };

    const saveState =() => {
        const json = JSON.stringify(labels);
        sessionStorage.setItem("faces", json);
        console.log("State saved")
    }

    const handleSave = async () => {

        if (!selectedFace || !name) {
            console.log("Can't save. Missing data!")
        }

        // Save the label for the selected face
        console.log("Save clicked", selectedFace)
        const label = { name: name, descriptor: selectedFace.descriptor };
        setLabels({ ...labels, label});
        console.log("Saved");
    };

    const startMatching = async () => {

        console.log("Matching...");
        const detections = await faceapi
            .detectAllFaces(imgRef.current)
            .withFaceLandmarks()
            .withFaceDescriptors();
       
        console.log("Faces detected?", detections);

        // Load the saved face descriptors from the JSON file
        const savedLabels = sessionStorage.getItem('faces');
        console.log("Loaded State", savedLabels)

        // Compute the face descriptor
        const faceDescriptor = detections[0].descriptor;
        console.log("Face?", faceDescriptor)


        // Compare the computed face descriptor with the saved face descriptors
        const matchedLabel = () => {
            const savedDescriptor = faceDescriptor.label.descriptor;
            const distance = faceapi.euclideanDistance(savedDescriptor, faceDescriptor);
            return distance < 0.6; // You can adjust the threshold as needed
        };

        if (matchedLabel) {
            console.log(`Match found: ${matchedLabel.name}`);
        } else {
            console.log('No match found');
        }
        };

      
   
    return (
        <Container>
            <InfoBox>
                <Loading style={{display: isBusy ? "" : "none"}} /> {appStatus} 
            </InfoBox>
            {mainImage &&
                <ImageWrapper>
                   
                    <ImageDiv 
                        position="relative"
                        ref={imgRef} 
                        crossOrigin="anonymous" 
                        src={mainImage.src} 
                        alt="" 
                        width={displayDimensions.width} 
                        height={displayDimensions.height} 
                    />
                    
                    {detections.map((detection, index) => (
                        <>
                        <div
                            key={index + 1} // Added a 1, as we're using the same index twice. So we just negate the value below. Used +1 here as 0 * -1 is still 0.
                            style={{
                                position: "absolute",
                                top: detection.alignedRect.box.top,
                                left: detection.alignedRect.box.left,
                                width: detection.alignedRect.box.width,
                                height: detection.alignedRect.box.height,
                                border: selectedFace === detection ? "3px solid red" : "none",
                            }}
                             >
                        </div>

                        <div
                            key={index * -1}
                            style={{
                                position: "absolute",
                                top: (detection.alignedRect.box.top + 1),
                                left: (detection.alignedRect.box.left + 1),
                                width: (detection.alignedRect.box.width + 2),
                                height: (detection.alignedRect.box.height + 2),
                                border: "1px dashed grey",
                                cursor: "pointer",
                            }}
                            onClick={(event) => handleFaceClick(event, detection)} >
                        </div>
                        </>
                    ))}
                    
                    <canvas 
                        ref={canvasRef} 
                        width={displayDimensions.width} 
                        height={displayDimensions.height} 
                        border = "1px solid #ff0000" 
                    />
                </ImageWrapper>
            }
           
           {selectedFace && (
                <>
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder="Enter name"
                    />
                    <button onClick={() => handleSave()}>Save</button>
                    <button onClick={() => saveState()}>Save State</button>
                    <button onClick={() => startMatching()}>Match</button>
                </>
            )}
        </Container>
    )
}

export default FaceMatch


