import styled from 'styled-components';


const Container = styled.div`
    display: flex;
    gap: 10px;
    margin: 30px 0px;
`

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

const Details = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${({theme}) => theme.text};

`

const Name = styled.span`
    font-size: 13px;
    font-weight: 500;
`
const Date = styled.span`
font-size: 12px;
font-weight: 400;
color: ${({theme}) => theme.textSoft};
margin-left: 5px;
`
const Text = styled.span`
    font-size: 14px;
`

const Comment = () => {

    return (
        
            <Container>
                <Avatar src="https://images.pexels.com/photos/1323550/pexels-photo-1323550.jpeg?auto=compress&cs=tinysrgb&w=250" />
                <Details>
                    <Name>
                        Craig Lister <Date>1 day ago</Date>
                    </Name>
                    <Text>
                    Wow, what a breathtaking photo of that jet! The way the light reflects off its metallic body and the clouds in the background create an incredible sense of depth and movement. You can almost feel the rush of air as it soars through the sky. The photographer has truly captured the essence of the power and beauty of modern aviation.
                    </Text>
                </Details>
            </Container>
           
    )
}

export default Comment